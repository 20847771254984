import setReviewContent from './import/modules/setReviewContent';
import clearReviewContent from './import/modules/clearReviewContent';
import Inputmask from "inputmask";

$(function () {
    $('.js-modal-open').click(function (e) {
        e.preventDefault();

        let modal = $(this).data('modal');
        if($('.modal').attr('id') === 'modal-review') {
            setReviewContent(this);
        }
        $('#' + modal).addClass('opened');
        $('html').addClass('ov-hidden');

        if($(this).attr('data-title')) {
            $('#' + modal).find('.modal__title').html($(this).attr('data-title'));
            $('#' + modal).find('.modal__subtitle').hide();
        }
    });

    $('.js-modal-close').click(function (e) {
        e.preventDefault();
        $('html').removeClass('ov-hidden');
        $('.modal.opened').find('.modal__subtitle').show();
        $('.modal.opened').find('.modal__title').html('Заявка на бесплатный аудит');
        if($('.modal').attr('id') === 'modal-review') {
            clearReviewContent();
        }

        $('.modal.opened').removeClass('opened');
    });



    $('body').on('click', '.menu__dropdown', function (e) {
        e.preventDefault();

        $(this).closest('.menu__entry').find('.header__submenu').toggle();
    });

    $('body').on('click', '.js-header-toggle', function (e) {
        e.preventDefault();

        $(this).closest('.header').toggleClass('opened');

        $('html').toggleClass('ov-hidden');
    });

    $("#modal-request button[type=submit]").on("click", function (e) {
        e.preventDefault();
        let $form = $("#modal-request form");
        let errors;
        $form.find("input[required]").each(function () {
            let value = $(this).val();
            if (!value) {
                $(this).closest('label').addClass("error");
                errors = true;
            }
        });
        if (errors) return false;
        sendOrder($form);
    });

    function sendOrder($form) {
        let data = {};

        data.userName = $form.find('input[name=userName]').val();
        data.userEmail = $form.find('input[name=userEmail]').val();
        data.userPhone = $form.find('input[name=userPhone]').val();
        data.userLandingId = $form.find('input[name=userLandingId]').val();
        data.userCompany = $form.find('input[name=userCompany]').val();

        $.ajax({
            url: "https://rebrainme.com/lead-universal-signup",
            method: 'POST',
            data: JSON.stringify(data)
        }).done(function (data) {
            if (data.status === 'err') {
                $form.find(".form__error").html(data.status);
            } else {
                $form.hide().next('.modal__success').show();
            }
        });
    }

    Inputmask({"mask": "+ 7(999) 999-9999"}).mask($("input[type=tel]"));

    let match = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    $('input[type=email]').blur(function () {
        let val = $(this).val();

        if(!match.test(val)) {
            $(this).closest('label').addClass('error');
            $(this).closest('form').find('.form__error').html('Введите корректный E-mail адрес').show();
        } else {
            $(this).closest('label').removeClass('error');
            $(this).closest('form').find('.form__error').html('').hide();
        }

        $('label.error input[type=email]').keyup(function () {
            let val = $(this).val();

            if(match.test(val)) {
                $(this).closest('label').removeClass('error');
                $(this).closest('form').find('.form__error').html('').hide();
            }
        })
    })

    $('input[type=text], input[type=tel]').blur(function () {
        let val = $(this).val();

        if(!val) {
            $(this).closest('label').addClass('error');
            $(this).closest('form').find('.form__error').html('Пожалуйста, заполните обязательные поля!').show();
        } else {
            $(this).closest('label').removeClass('error');
            $(this).closest('form').find('.form__error').html('').hide();
        }

        $('label.error input[type=text], label.error input[type=tel]').keyup(function () {
            let val = $(this).val();

            if(match.test(val)) {
                $(this).closest('label').removeClass('error');
                $(this).closest('form').find('.form__error').html('').hide();
            }
        })
    })

})